.service-page {
  .content {
    padding: 45px 20px 55px;
    margin: 0 auto;
    max-width: 890px;
  }
}

@media (min-width: $breakNavWidth) {
  .service-page {
    .content {
      padding: 50px 0px;
    }
  }
}
