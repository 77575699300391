.contactbox {
  margin: 20px 0;
  background-color: #e8f3f4;
  padding: 45px 10px;
  text-align: center;
  color: #37666c;
  font-weight: $fontWeightLight;
  font-size: $fontSize18;
  line-height: 1.4;
  h3 {
    font-size: $fontSize26;
    font-weight: $fontWeightLight;
    color: $colorPrimary;
    margin-bottom: 15px;
  }
  a {
    font-weight: $fontWeightSemibold;
    color: #37666c;
    text-decoration: underline;
  }
}

@media (min-width: 800px) {
  .contactbox {
    font-size: $fontSize22;
    padding: 45px 20px;
    h3 {
      font-size: $fontSize30;
      margin-bottom: 5px;
    }
  }
}

@media (min-width: $breakNavWidth) {
  .contactbox {
    font-size: $fontSize26;
    padding: 45px 20px;
    h3 {
      font-size: $fontSize34;
    }
  }
}
