header {
  position: relative;
  z-index: 1;
  .info-bar-container {
    background-color: $colorPrimary;
    padding: 5px 10px;
  }
  .info-bar {
    color: #b8e0e4;
    text-align: center;
    .fa {
      color: #fff;
      font-size: $fontSize20;
    }
    .call a,
    .hours strong {
      font-weight: 600;
      color: #fff;
    }
    .call {
      display: flex;
      align-items: center;
      justify-content: center;
      .fa {
        margin-right: 3px;
      }
      a {
        margin-left: 3px;
      }
    }
  }
  .header-bar {
    height: 48px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    position: relative;
    box-shadow: 0 1px 1px rgba(0,0,0,0.4);
    z-index: 3;
    .brand {
      display: block;
      background: no-repeat scroll -7px 50%;
      height: 100%;
      width: 60px;
      background-size: cover;
      span {
        display: none;
      }
    }
    .hamburger {
      display: block;
      overflow: hidden;
      margin: 0;
      padding: 0;
      width: 30px;
      height: 24px;
      border: none;
      cursor: pointer;
      background: transparent;
      position: absolute;
      top: 12px;
      right: 20px;
      &:focus {
        outline: none;
      }
      span {
        top: 10px;
      }
      .ddd,
      .ddd:before,
      .ddd:after,
      .x,
      .x:after,
      .x:before {
        display: block;
        position: absolute;
        right: 0;
        height: 4px;
        width: 100%;
        background: $colorPrimary;
      }
      .ddd:before,
      .ddd:after,
      .x:after,
      .x:before {
        content: '';
      }
      .ddd::before,
      .x:before {
        top: -10px;
      }
      .ddd::after,
      .x::after, {
        bottom: -10px;
      }
      .ddd {
        opacity: 1;
        transition: opacity .3s ease .1s;
      }
      .x {
        background: transparent;
        opacity: 0;
        transition: opacity .3s ease 0s;
      }
      .x:before {
        top: 0;
        transform: rotate(45deg);
      }

      .x::after {
        bottom: 0;
        transform: rotate(-45deg);
      }
    }
  }
}

.fixed-nav header {
  position: fixed;
  top: 0;
  background-color: #fff;
  z-index: 9000;
  width: 100%;
  box-shadow: none;
  .info-bar-container {
    display: none;
  }
}
.fixed-nav main {
  padding-top: 106px;
}

.nav-open {
  overflow: hidden;
  main, footer {
    transform: translateX(-280px);
  }
  nav {
    transform: translateX(0);
    box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.4);
  }

  header .header-bar .hamburger {
    .ddd {
      opacity: 0;
      transition: opacity .3s ease 0s;
    }
    .x {
      opacity: 1;
      transition: opacity .3s ease .1s;
    }
  }
}

main, footer {
  transform: translateX(0);
  transition: transform .3s ease-out;
}

nav {
  position: absolute;
  z-index: 2;
  width: 280px;
  right: 0;
  background: #fff;
  transform: translateX(280px);
  transition: transform .3s ease-out, box-shadow 0s ease-out;
  box-shadow: none;
  min-height: 100vh;

  & > ul {
    padding: 0;
    list-style: none;
    height: 100%;
    margin: 0;
    li {
      padding: 15px 20px;
      border-bottom: 1px solid #DCDCDC;
    }
    li a {
      text-transform: uppercase;
      color: #6c6d74;
      font-size: $fontSize20;
      position: relative;
    }
  }
}

@media (min-width: 440px) {
  header .header-bar .brand {
    width: 320px;
    background-size: contain;
  }
}


@media (min-width: 800px) {
  header .info-bar {
    display: flex;
    max-width: 1250px;
    margin: 0 auto;
    .call,
    .hours {
      text-align: left;
      width: 50%;
      display: block;
    }
    .hours {
      text-align: right;
    }
  }
}

@media (min-width: $breakNavWidth) {
  header {
    box-shadow: none;
    position: static;
  }
  header .info-bar-container {
    padding: 5px 20px;
  }
  header .header-bar-container {
    max-width: 1280px;
    height: 85px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    .header-bar {
      height: 100%;
      box-shadow: none;
      padding-left: 0px;
    }
    .hamburger {
      display: none;
    }
    .brand {
      width: 300px;
      height: 100%;
      background-size: contain;
    }
    nav {
      text-align: right;
      position: static;
      width: auto;
      transform: translateX(0);
      min-height: 0;
      box-shadow: none;
      & > ul li {
        display: inline-block;
        border: none;
        padding: 0 0 0 30px;
        &:first-child {
          padding-left: 0;
        }
        a {
          font-size: $fontSize18;
          &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: 0;
            background: #6c6d74;
            left: 0;
            transform: scaleX(0);
            transform-origin: 50% 0;
            transition: transform $animationSpeed ease-out;
          }
          &:hover:after {
            transform: scaleX(1);
          }
        }
        &.active a{
          color: $colorPrimary;
          &:after {
            display: none;
          }
        }
      }
    }
  }
  .fixed-nav main {
    padding-top: 119px;
  }
}

@media (min-width: 1100px) {
  header .header-bar-container {
    height: 95px;
    max-width: 100%;
    padding: 0 20px;
    .brand {
      width: 400px;
    }
    nav > ul{
      li {
        padding-left: 35px;
      }
    }
  }
  .fixed-nav main {
    padding-top: 129px;
  }
}


@media (min-width: 1200px) {
  header .header-bar-container {
    height: 100px;
    .brand {
      width: 445px;
    }
    nav > ul{
      li {
        padding-left: 40px;
        a {
          font-size: $fontSize20;
        }
      }
    }
  }
  .fixed-nav main {
    padding-top: 134px;
  }
}

@media (min-width: 1290px) {
  header .header-bar-container {
    max-width: 1250px;
    padding: 0;
    .brand {
      width: 450px;
    }
  }
}

@media (min-width: 1333px) {
  header .header-bar-container,
  header .info-bar {
    max-width: 1280px;
  }
}
