

.faq-container {
  padding: 70px 20px;
  .faq-introduction {
    max-width: 890px;
    margin: 0 auto;
    color: #1b4e55;
    font-size: $fontSize20;
    text-align: justify;
    h3 {
      font-weight: $fontWeightLight;
      font-size: $fontSize34;
      color: $colorPrimary;
      margin-bottom: 25px;
      text-align: center;
    }
  }
  .faq-list {
    max-width: 890px;
    margin: 45px auto 50px;
    & > h3 {
      font-size: $fontSize24;
      padding: 32px 60px 32px 30px;
      margin: 0 0 5px;
      background-color: #e8f3f4;
      color: #1b4e55;
      font-weight: $fontWeightNormal;
      outline: 0 !important;
      cursor: pointer;
      position: relative;
      &:after {
        content: "\f105";
        font-family: FontAwesome;
        position: absolute;
        right: 30px;
        transform: translateX(0) rotate(0);
        transition: transform $animationSpeed ease-out;
        top: 50%;
        margin-top: -18px;
      }
      &:hover:after {
        transform: translateX(5px);
      }
      &.ui-accordion-header-active {
        font-weight: $fontWeightSemibold;
        margin: 0;
        &:after {
          transform: translateX(0) rotate(90deg);
        }
      }
      & + div {
        background-color: #f3f9f9;
        padding: 0;
        & > ul {
          list-style: none;
          padding: 20px 30px;
          li {
            position: relative;
            padding: 0;
            margin-bottom: 20px;
          }
        }
      }
    }
    .ui-accordion-content-active {
      margin-bottom: 25px;
    }
    .answer-icon-container {
      height: 80px;
      width: 80px;
      border-radius: 50%;
      background-color: $colorPrimary;
      display: flex;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      .answer-icon {
        height: 100%;
        width: 100%;
        background: no-repeat scroll center;
        background-size: contain;
      }
    }
    .answer-content{
      margin-left: 100px;
      min-height: 80px;
      padding: 10px 0;
      .answer-item-name {
        color: #1b4e55;
        font-weight: $fontWeightSemibold;
        font-size: $fontSize20;
        margin: 0;
        &:after {
          content: ':';
        }
      }
      p {
        font-size: $fontSize18;
        margin: 0 0 20px;
      }
      h3 {
        font-size: $fontSize18;
        font-weight: $fontWeightSemibold;
      }
      ul {
        list-style-type: disc;
        margin-bottom: 20px;
      }
    }
    .continue-link {
      display: block;
      font-size: $fontSize24;
      font-weight: $fontWeightLight;
      color: #fff;
      padding: 22px 0 22px 130px;
      background-color: #60a5af;
      margin: 0 0 3px;
      position: relative;
      &:after {
        content: "\f105";
        font-family: FontAwesome;
        position: absolute;
        right: 30px;
        transform: translateX(0) rotate(0);
        transition: transform $animationSpeed ease-out;
      }
      &:hover:after {
        transform: translateX(5px);
      }
    }
  }
}



@media (max-width: 600px) {
  .faq-container .faq-list .answer-icon-container {
    position: relative;
    margin: 0 auto;
  }
  .faq-container .faq-list .answer-content {
    margin-left: 0;
  }
}

@media (max-width: 989px) {
  .faq-container .faq-list .continue-link {
    padding-left: 30px;
    padding-right: 30px;
  }
}
