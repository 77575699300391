.main-fields-menu {
  .main-field {
    display: block;
    position: relative;
    .main-field-image {
      height: 300px;
      background: no-repeat center;
      background-size: cover;
      margin-bottom: 4px;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #3b909c;
        opacity: .5;
        transition: opacity $animationSpeed ease-out;
      }
    }
    .main-field-name {
      background: #b8e0e4;
      color: #174c54;
      font-size: $fontSize18;
      font-weight: $fontWeightNormal;
      text-align: center;
      padding: 20px 10px 15px;
      margin: 0 0 4px;
      white-space: nowrap;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &:hover .main-field-image:after {
      opacity: 0;
    }
    &.no-image {
      .main-field-image {
        display: none;
      }
    }
  }
}


.main-fields-menu .main-field {
  &.ct .main-field-name { background: #b8e0e4;}
  &.mrt .main-field-name{
    background: #92ccd0;
  }
  &.nm .main-field-name {
    background: #3b909c;
    color: #0f3a40;
  }
}

@media (min-width: $breakNavWidth) {
  .main-fields-menu {
    display: flex;
    .main-field {
      width:4/12*100%;
      .main-field-image {
        height: 400px;
      }
      .main-field-name {
        padding: 45px 10px 35px;
        font-size: 1.8vw;
        margin-bottom: 0;
      }
      &.no-image {
        .main-field-image {
          display: none;
        }
        .main-field-name {
          padding: 21px 10px 18px;
          &:after {
            content: "\f107";
            font-family: FontAwesome;
            margin-left: 5px;
            display: inline-block;
            transform: translateY(0px);
            transition: transform $animationSpeed ease-out;
          }
        }
      }
      &.no-image:hover {
        .main-field-name:after {
          transform: translateY(4px);
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .main-fields-menu {
    .main-field {
      .main-field-image,
      .main-field-image-overlay {
        height: 500px;
      }
      .main-field-name {
        font-size: $fontSize24;
      }
    }
  }
}
