.institute {
  .management-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10%;
  }
  .manager {
    margin-bottom: 10px;
    width: 100%;
    background-color: #e8f3f4;
    .manager-image {
      height: 300px;
      background: no-repeat center;
      background-size: cover;
    }
    .manager-info {
      padding: 40px 20px 30px;
      text-align: center;
      color: #1b4e55;
      line-height: 1.8;
      h3 {
        font-size: $fontSize24;
        margin-bottom: 0;
        font-weight: $fontWeightSemibold;
      }
      h4 {
        font-size: $fontSize18;
        text-transform: uppercase;
        margin: 0 0 20px;
        font-weight: $fontWeightSemibold;
      }
    }
  }
}

@media (min-width: 600px) {
  .institute .management-container {
    padding: 0;
  }
  .institute .manager {
    width: 50%;
    background-color: transparent;
  }
}

@media (min-width: $breakNavWidth) {
  .institute {
    .management-container {
      display: flex;
      flex-wrap: wrap;
      max-width: 1440px;
      margin: 0 auto;
    }
    .manager {
      width: 4/12*100%;
      .manager-image {
        height: 500px;
        background: no-repeat center;
        background-size: cover;
      }
    }
  }
}
