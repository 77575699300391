$breakNavWidth: 990px;

//$mobileNavHeight: 48px;

$colorDefault: #1b4e55;
$colorPrimary: #3b909c;

$fontSize8: 0.5rem;;
$fontSize9: 0.5625rem;
$fontSize10: 0.625rem;
$fontSize11: 0.6875rem;
$fontSize12: 0.75rem;
$fontSize13: 0.8125rem;
$fontSize14: 0.875rem;
$fontSize15: 0.9375rem;
$fontSize16: 1rem;
$fontSize18: 1.125rem;
$fontSize20: 1.25rem;
$fontSize22: 1.375rem;
$fontSize24: 1.5rem;
$fontSize25: 1.563rem;
$fontSize26: 1.625rem;
$fontSize28: 1.75rem;
$fontSize30: 1.875rem;
$fontSize32: 2rem;
$fontSize34: 2.125rem;
$fontSize36: 2.25rem;
$fontSize38: 2.375rem;
$fontSize40: 2.5rem;
$fontSize42: 2.625rem;
$fontSize44: 2.75rem;

$fontWeightLight: 300;
$fontWeightNormal: 400;
$fontWeightSemibold: 600;

$animationSpeed: 0.2s;
