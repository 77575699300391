footer {
  .footer-container {
    background-color: #d5ebed;
  }
  .footer-menu {
    padding: 50px 10%;
    ul {
      list-style: none;
      padding: 0;
      h5 {
        font-weight: 400;
        text-transform: uppercase;
        color: #23575e;
        font-size: $fontSize16;
        margin-bottom: 14px;
      }
    }
    & > ul > li {
      margin:0 0 40px;
      width: 100%;
      max-width: 270px;
      & > ul {
        padding-left: 10px;
        & > li {
          line-height: 2;
        }
      }
    }
    .address,
    .contact {
      color: $colorPrimary;
      .company-name {
        font-weight: $fontWeightSemibold;
        color: $colorPrimary;
      }
      a {
        font-weight: $fontWeightSemibold;
        border-bottom: 1px solid #3b909c;
      }
      .desc {
        font-weight: $fontWeightSemibold;
      }
    }
    .logo-container {
      margin: 0;
    }
    .logo {
      height: 70px;
      margin: 0;
      background: center no-repeat;
      background-size: contain;
    }
    .link-list > ul > li {
      position: relative;
      transform: translateX(0);
      transition: transform $animationSpeed ease-out;
      &:before {
        content: "\f105";
        font-family: FontAwesome;
        position: absolute;
        left: -10px;
        opacity: 0;
        transition: opacity $animationSpeed ease-out;
        color: $colorPrimary;
      }
      &:hover {
        transform: translateX(5px);
        &:before {
          opacity: .8;
        }
      }
    }
  }
  

  .close-container {
    background-color: #92ccd0;
    font-size: $fontSize14;
    color: #37666c;
    padding: 15px 5px 25px;
    a {
      color: #37666c;
    }
    .copyright-container,
    .imprint {
      text-align: center;
    }
    .copyright-company {
      white-space: nowrap;
      display: inline-block;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}




@media (min-width: 650px) {
  footer .footer-menu {
    & > ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      align-items: center;
      & > li {
        width: 50%;
        &:nth-child(2n) {
          margin-left: 10%;
          max-width: 40%;
        }
      }
    }
    .logo {
      height: 105px;
    }
  }
}

@media (min-width: $breakNavWidth) {
  footer .footer-menu {
    padding-left: 30px;
    padding-right: 30px;
    & > ul {
      display: block;
      text-align: center;
      & > li {
        display: inline-block;
        width: auto;
        margin-right: 50px;
        text-align: left;
        &:nth-child(2n) {
          margin-left: 0;
          width: auto;
        }
        &.address .company-name{
          max-width: 225px;
        }
        &.logo-container {
          display: block;
          width: 100%;
          margin: 25px auto 0;
        }
      }
    }
  }
  footer .close-container {
    padding: 15px 5px;
    .close {
      display: flex;
      margin: 0 9%;
      .copyright-container,
      .imprint {
        width: 50%;
        text-align: left;
      }
      .imprint {
        text-align: right;
      }
      .copyright-company {
        width: auto;
        display: inline;
      }
    }
  }
}

@media (min-width: 1150px) {
  footer .footer-menu {
    padding-left: 5%;
    padding-right: 5%;
    & > ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      align-items: center;
      max-width: 1280px;
      margin: 0 auto;
      & > li {
        width: 21%;
        max-width: none;
        display: block;
        margin-right: 0;
        &:nth-child(2n) {
          max-width: none;
          width: 21%;
        }
        &.logo-container {
          display: block;
          width: auto;
          margin: 0;
          width: 16%;
        }
      }
    }
  }
  footer .close-container {
    .close {
      margin: 0 4%;
    }
  }
}

@media (min-width: 1500px) {
  footer .close-container {
    .close {
      margin: 0 auto;
      max-width: 1280px;
    }
  }
}
