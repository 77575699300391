.bg-img-header {
  margin: 0 0 10px;
  padding: 25px 10px;
  background: no-repeat center;
  background-size: cover;
  h3 {
    margin: 0;
    font-weight: $fontWeightLight;
    color: #1f6a75;
    text-align: center;
    font-size: 36px;
  }
}

@media (min-width: $breakNavWidth) {
  .bg-img-header {
    padding: 80px 10px;
    h3 {
      font-size: 52px;
    }
  }
}
