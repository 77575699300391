.contact-information-container {
  .contact-info {
    color: #1b4e55;
    padding: 35px 30px;
    margin: 0 auto;
    .address {
      h3 {
        font-weight: $fontWeightSemibold;
        font-size: $fontSize24;
        margin-bottom: 25px;
      }
    }
    .address,
    .contact-us {
      margin-bottom: 25px;
    }
    .contact-us {
      span {
        font-weight: $fontWeightSemibold;
      }
      .mail-to {
        text-decoration: underline;
        font-weight: $fontWeightSemibold;
      }
      a {
        color: #1b4e55;
      }
    }
    .hours {
      h3 {
        font-size: $fontSize18;
      }
    }

    dl {
      margin: 0;
      dt{
        float: left;
        width: 105px
      }
      dd {
        margin-left: 125px;
      }
    }

    dl dt:after {
      content:':';
    }
  }
  .map-info-container {
    margin-bottom: 10px;
    .map {
      height: 300px;
      background: #252525;
    }
  }
  .direction-container {
    .direction-title {
      background-color: #e8f3f4;
      color: $colorPrimary;
      font-weight: $fontWeightLight;
      font-size: $fontSize30;
      text-align: center;
      padding: 35px 20px;
    }
    .directions {
      padding: 40px 20px;
    }
    .direction {
      margin: 0 auto 40px;
      max-width: 890px;
      text-align: justify;
      h3 {
        color: $colorPrimary;
        font-weight: $fontWeightLight;
        font-size: $fontSize24;
        text-align: center;
        margin-bottom: 25px;
      }
      p {
        margin-bottom: 20px;
      }
    }
  }
}

@media (min-width: $breakNavWidth) {
  .contact-information-container {
    .map-info-container {
      display: flex;
      align-items: center;
      .map {
        width: 50%;
        height: 500px;
        background: #252525;
      }
      .contact-info {
        width: 50%;
      }
    }
    .direction-container {
      .direction-title {
        font-size: $fontSize34;
        padding: 55px 20px;
      }
      .direction {
        margin: 0 auto 50px;
        h3 {
          font-size: $fontSize26;
          margin-bottom: 30px;
        }
        p {
          margin-bottom: 25px;
        }
      }
    }
    .contact-info {
      padding: 0 30px;
    }
  }
}
