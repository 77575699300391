html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  position: relative;
  font-family: 'Titillium Web', sans-serif;
  font-weight: normal;
  box-sizing: border-box;
  min-height: 100%;
}

a:focus, button {
  outline: none;
}

a {
  color: $colorPrimary;
}
a,
a:visited {
  text-decoration: none;
}
a[ng-click]{
  cursor: pointer;
}

h1,h2,h3,h4,h5 {
  margin-top: 0;
}


main {
  margin: 0 auto;
  min-height: 100%;
  color: #12363b;
  font-size: $fontSize18;
  p {
    margin: 0;
  }
  h1, h2 {
    font-weight: $fontWeightSemibold;
    font-size: $fontSize22;
    color: #12363b;
  }
  h3 {
    margin: 35px 0 0;
    font-size: $fontSize22;
    font-weight: $fontWeightSemibold;
    &:first-child {
      margin: 0;
    }
  }
  h4 {
    font-size: $fontSize18;
    margin: 35px 0 0;
  }
  ul {
    padding-left: 22px;
    margin: 0 0 35px;
    li {
      padding-left: 10px;
    }
  }
  .draft {
    padding: 35px 20px;
    background-color: #e8f3f4;
    margin-bottom: 10px;
    h3,
    p {
      max-width: 890px;
      margin: 0 auto;
    }
    h3 {
      font-size: $fontSize30;
      font-weight: $fontWeightLight;
      color: $colorPrimary;
      text-align: center;
      margin-bottom: 30px;
    }
    p {
      font-size: $fontSize18;
      font-weight: $fontWeightNormal;
      color: $colorDefault;
      text-align: justify;
    }
  }
}





@media (min-width: $breakNavWidth) {
  body {
    overflow-y: scroll;
    background-size: auto;
  }

  main {
    min-height: initial;
    font-size: $fontSize18;
    p {
      margin: 0;
    }
    h3 {
      margin: 35px 0 0;
      font-size: $fontSize22;
      font-weight: $fontWeightSemibold;
    }
    h4 {
      font-size: $fontSize18;
      margin: 35px 0 0;
    }
    ul {
      padding-left: 22px;
      margin: 0 0 35px;
      li {
        padding-left: 28px;
      }
    }
    .draft {
      padding: 50px 10px;
      h3 {
        font-size: $fontSize34;
      }
      p {
        font-size: $fontSize20;
      }
    }
  }
}
